import request from '@/utils/request'


// 查询用户认证列表
export function listUser(query) {
  return request({
    url: '/biz/userAttest/list',
    method: 'get',
    params: query
  })
}

// 查询用户认证分页
export function pageUser(query) {
  return request({
    url: '/biz/userAttest/page',
    method: 'get',
    params: query
  })
}

// 查询用户认证详细
export function getUser(data) {
  return request({
    url: '/biz/userAttest/detail',
    method: 'get',
    params: data
  })
}

// 新增用户认证
export function addUser(data) {
  return request({
    url: '/biz/userAttest/add',
    method: 'post',
    data: data
  })
}

// 修改用户认证
export function updateUser(data) {
  return request({
    url: '/biz/userAttest/edit',
    method: 'post',
    data: data
  })
}

// 删除用户认证
export function delUser(data) {
  return request({
    url: '/biz/userAttest/delete',
    method: 'post',
    data: data
  })
}
